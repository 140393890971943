import {IGalleryControllerConfig, GridType} from '../types/galleryTypes';
import {GalleryStore} from './GalleryStore';
import {getStyleParamsWithDefaults} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/getStyleParamsWithDefaults';
import {ProductsService} from '../services/ProductsService';
import {I$W, IWidgetController} from '@wix/native-components-infra/dist/es/src/types/types';
import {IControllerFactoryConfig} from '@wix/wixstores-client-core/dist/es/src/viewer-script/createViewerScript';
import {getStyleParamsWithDefaultsFunc} from '../getStyleParamsWithDefaultsFunc';
import {
  MAX_COLS,
  MAX_ROWS,
  DEFAULT_AUTO_GRID_PRODUCTS_COUNT,
  DEFAULT_MOBILE_PRODUCTS_COUNT,
  DEFAULT_ROWS,
  DEFAULT_COLS,
  Experiments,
} from '../constants';

function getInitialProductsCountToFetch(
  isMobile: boolean,
  isAutoGrid: boolean,
  rows: number = DEFAULT_ROWS,
  cols: number = DEFAULT_COLS,
  autoGridProductsCount: number
) {
  if (isMobile) {
    return DEFAULT_MOBILE_PRODUCTS_COUNT;
  }
  if (isAutoGrid) {
    return autoGridProductsCount;
  }
  return rows * cols;
}

export function galleryController(controllerFactoryConfig: IControllerFactoryConfig): IWidgetController {
  let galleryStore: GalleryStore;
  const {compId, config, setProps, context: factoryContext, type, reportError} = controllerFactoryConfig;
  const {
    style: {styleParams},
  } = config;

  return {
    pageReady: () => {
      const styleParamsWithDefaults = getStyleParamsWithDefaults(styleParams, () =>
        getStyleParamsWithDefaultsFunc({style: {styleParams}, dimensions: undefined})
      );

      const isMobile = factoryContext.siteStore.isMobile();
      const isAutoGrid = styleParamsWithDefaults.numbers.gallery_gridType === GridType.AUTO;

      let initialProductsCount = getInitialProductsCountToFetch(
        isMobile,
        isAutoGrid,
        styleParamsWithDefaults.numbers.galleryRows,
        styleParamsWithDefaults.numbers.galleryColumns,
        styleParamsWithDefaults.numbers.gallery_productsCount
      );

      if (factoryContext.siteStore.isEditorMode()) {
        initialProductsCount = isAutoGrid ? DEFAULT_AUTO_GRID_PRODUCTS_COUNT : MAX_ROWS * MAX_COLS;
      }

      const withOptions =
        factoryContext.siteStore.experiments.enabled(Experiments.ProductOptionsInGalleryComp) &&
        styleParamsWithDefaults.booleans.gallery_showProductOptionsButton;
      const productsService = new ProductsService(
        factoryContext.siteStore,
        initialProductsCount,
        'Grid Gallery',
        withOptions
      );
      galleryStore = new GalleryStore(
        styleParamsWithDefaults,
        config,
        setProps,
        factoryContext.siteStore,
        productsService,
        compId,
        type,
        reportError
      );
      return galleryStore.setInitialState().catch(reportError);
    },
    updateConfig: async (
      _$w: I$W,
      {style: {styleParams: newStyleParams}, publicData: newPublicData}: IGalleryControllerConfig
    ) => {
      await galleryStore.updateState(newStyleParams, newPublicData);
    },
  };
}
